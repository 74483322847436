.is_clickable {
  cursor: hand;
  cursor: pointer;
}
.nstSlider {
  cursor: hand;
  cursor: pointer;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  position: relative;
  width: 300px;
  height: 20px;
  background: #113344;
}
.nstSlider .leftGrip.gripHighlighted {
  background: #113344;
}
.nstSlider .rightGrip.gripHighlighted {
  background: #113344;
}
.nstSlider .rightGrip,
.nstSlider .leftGrip {
  position: absolute;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  background: #ffffff;
}
.nstSlider .bar {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  position: absolute;
  background: #ffffff;
  height: 8px;
  top: 6px;
}
.nstSlider .highlightPanel {
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  position: absolute;
  background: #e87722;
  height: 20px;
  top: 0;
}
